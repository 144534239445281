<template>
<section class="page_title  pdt_80  pdb_40">
    <div class="container">
        <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12 text-center">
            <!---------col----------->
            <!---------col-end---------->
            </div>
            <!----------row-end---------->
        </div>
    </div>
</section>
<section class="blog_detail no_sidebar  pdb_100">
      <div class="container">
            <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
            <!---------col----------->
            <div class="featured_image">
                <div class="image_box">
                    <img src="@/assets/image/news/storyghana.jpg" style="width:100%; height:420px !important; object-fit:cover; object-position:top" class="img-fluid" alt="img" />
                </div>
            </div>
            <!---------col-end---------->
            </div>
            <!----------row-end---------->
        </div>


        <div class="row">
            <!---------row----------->
            <div class="col-lg-2 col-md-12">
            <!---------enpty_column----------->
            </div>
            <div class="col-lg-8 col-md-12">
            <!---------col----------->
            <div class="blog_detail_content">
                <!-----blog_upper_box------->
                <div class="blog_detail_upper_box">
                    <h1>World Vision Supports Ghana Health Service in response to COVID-19</h1>
                    <ul class="bread_crumb text-center">`
                        <li class="bread_crumb-item"><a href="#">   <i class="linearicons-home4 home_icon"></i> </a></li>
                        <li class="bread_crumb-item"><a href="#">Media</a></li>
                        <li class="bread_crumb-item"><a href="#">News</a></li>
                        <li class="bread_crumb-item active">World Vision Supports Ghana Health Service in response to COVID-19</li>
                    </ul>
                    <ul class="blog_details_elements">
                        <li><small class="linearicons-calendar-full"></small> 25 Mar 2020</li>
                        <li><small class="linearicons-user"></small> Author: <a href="#">World Vision Ghana</a></li>
                        <li><small class="linearicons-clipboard-empty"></small> Category: <a href="#">NGOs</a></li>
                    </ul>
                    <h6>World Vision Ghana has stepped up its collaboration with the Ministry of Health. As is usual with World Vision globally, we are working with the Ghana Health Service to print and distribute a total of 200,000 information, education and communication materials to enhance the Government's public education efforts. These materials include; posters, flyers and pull-up banners that will be displayed at health centers, airports and all public places for public education. </h6>
                </div>
                <!-----blog_upper_box-end------>
                <!-----blog_lower_box------->
                <div class="blog_detail_lower_box">
                    
                    <p><span class="big_font mr-2"> W </span><span class="bg_font"> W </span>orld Vision Ghana has provided about 40,000 Personal Protective Equipment such as nose masks, coveralls, goggles, gloves, disposable towels for hand drying, veronica buckets, antibacterial liquid hand wash, large plastic basins and foot operated plastic waste bins.</P>
                    <p>According to National Director, Dickens Thunde, “the time has come for all of us to support the Government of Ghana to fight the Covid-19 pandemic. As we are all aware, the number of infected persons continues to rise and we all have the collective responsibility to support the efforts of Government so that together we can halt the further spread of the virus”.
                    </p>
                    <div class="text_box">
                    <p>The items valued at $80,000 were handed over to the Ghana Health Service on 25th March 2020.</p>

                    <p>With new cases of the global pandemic increasing daily, World Vision experts envisage that coronavirus will have a huge impact on the poorest and most vulnerable thus poses a major risk to the efforts and progress made to fight poverty and hunger globally. World Vision International is urging governments to support a united global effort to protect the most vulnerable.</p>

                    <p>World Vision International (WVI) has declared the COVID-19 a Global Health Emergency Response. As a result, here in Ghana, we have directed all our Area Programmes to review their programming activities to meet the needs for this response.</p>

                    <p>“World Vision Ghana is one of the largest providers of Water, Sanitation & Hygiene (WASH) services in this country so we are really promoting hand washing. We are also working towards strengthening the capacities of hospitals and clinics to support the management of the virus, especially by ensuring the availability of water and hand washing items. We have begun looking at ways to enhance our Water Sanitation & Hygiene (WASH) programme to further facilitate effective hygiene and safety practices to prevent the spread of the virus. Additionally, World Vision Ghana is working with faith leaders to promote safety practices to stem the spread of the virus among their congregants”.</p>

                    <p>The Government of Ghana has made drastic measures in an effort to limit the risk of further transmission and contain the outbreak by implementing specific directives on travel, conferencing, behaviour protocols and social gatherings in schools, colleges and at funerals as well as worship places.</p>

                    <p>Dickens Thunde commended the Government of Ghana for its management of the situation so far and called on everyone to abide by the prescribed hand washing and social distancing protocols among others, to prevent a further spread of the virus.</p>



                     
                    </div>
                    
                   
                 
                </div>
                <div class="more_content">
                    <div class="row">
                        <div class="col-lg-8">
                      
                        </div>
                        <div class="col-lg-4">
                        <ul class="media">
                            <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                            <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                        </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            <!---------col-end---------->
            </div>
            <div class="col-lg-2 col-md-12">
            <!---------enpty_column----------->
            </div>
            <!----------row-end---------->
        </div>
      </div>
</section>
</template>